// Accordion.js
import React, { useState, useMemo } from 'react';
import { switchConfiguration } from './config';
import placeholder from './assets/placeholder.jpg';

// Helper Function
function getUniqueCategories(materials) {
  return [...new Set(materials.flatMap((material) => material.category))];
}

function Accordion({
  title,
  materials,
  selected,
  onChange,
  customConfig,
  defaultRivestimento,
  type,
  language,
  resetMaterial
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(
    materials.length > 0 ? materials[0].category : null
  );
  const [openCollections, setOpenCollections] = useState({});
  const [selectedMaterial, setSelectedMaterial] = useState(null);

  const filteredMaterials = useMemo(() => {
    if (customConfig && customConfig.customCollection) {
      return materials.filter((material) =>
        customConfig.customCollection.includes(material.collection)
      );
    } else {
      // Category Filtering Logic
      return materials.filter((material) =>
        selectedCategory ? material.category === selectedCategory : true
      );
    }
  }, [customConfig, materials, selectedCategory]);


  // Event Handlers
  const handleMaterialChange = (newMaterial) => {
    if (newMaterial) {
      switchConfiguration(newMaterial, type);
      setSelectedMaterial(newMaterial);
      onChange(newMaterial);
    }
  }


  // Reset selectedMaterial to defaultRivestimento when resetMaterial is called
  React.useEffect(() => {
    if (resetMaterial) {
      setSelectedMaterial(defaultRivestimento);
      resetMaterial();
    }
  }, [resetMaterial, defaultRivestimento]);

  const handleCategoryChange = (newCategory) => {
    const filteredByCategory = materials.filter((material) =>
      newCategory ? material.category === newCategory : true
    )
    setSelectedCategory(newCategory);

  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleCollectionToggle = (collection) => {
    setOpenCollections((prevState) => ({
      ...prevState,
      [collection]: !prevState[collection],
    }));
  };

  return (
    <div className="ay-w-full ay-pt-4 ay-pb-6 hover:ay-cursor-pointer ay-border-b ay-border-b-gray-300">
      {/* Accordion Header */}
      <div className="ay-group ay-gap-x-4 ay-flex ay-justify-between ay-items-center" onClick={handleToggle}>
        <div className="ay-flex-1 ay-truncate">
          <h2 className="ay-font-bold ay-text-lg">{title}</h2>
          <h5 className="ay-text-lg ay-mt-1 ay-truncate">
            {language === 'it' ? selected?.name.it || 'seleziona' : selected?.name.en || 'select'}
          </h5>
        </div>
        <div className="ay-w-16 ay-h-16">
          {/* Thumbnail */}
          <img
            className="ay-w-full ay-aspect-square ay-object-cover ay-thumbnail"
            src={selected?.thumbnail || placeholder}
            alt={selected?.slug}
          />

          {/* SVG Icon */}
          <svg
            className="ay-w-full ay-h-full ay-svg-icon ay-hidden"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="50" height="50" fill="black" fillOpacity="0.15" />
            <path
              d="M34.1781 20.0107L29.9884 15.821C29.8666 15.6991 29.7219 15.6024 29.5626 15.5364C29.4034 15.4705 29.2327 15.4365 29.0603 15.4365C28.8879 15.4365 28.7173 15.4705 28.558 15.5364C28.3988 15.6024 28.2541 15.6991 28.1322 15.821L16.5719 27.3822C16.4498 27.5039 16.3529 27.6486 16.2869 27.8079C16.221 27.9672 16.1872 28.138 16.1875 28.3104V32.5001C16.1875 32.8482 16.3258 33.182 16.5719 33.4281C16.8181 33.6743 17.1519 33.8126 17.5 33.8126H21.6897C21.8621 33.8129 22.0329 33.7791 22.1922 33.7131C22.3515 33.6471 22.4961 33.5503 22.6178 33.4282L34.1781 21.8669C34.3 21.7451 34.3967 21.6004 34.4627 21.4411C34.5286 21.2819 34.5626 21.1112 34.5626 20.9388C34.5626 20.7664 34.5286 20.5958 34.4627 20.4365C34.3967 20.2773 34.3 20.1326 34.1781 20.0107ZM17.5459 28.0001L25.75 19.7951L27.5791 21.6251L19.375 29.8291L17.5459 28.0001ZM17.3125 32.5001V29.3576L20.6416 32.6876H17.5C17.4503 32.6876 17.4026 32.6678 17.3674 32.6326C17.3323 32.5975 17.3125 32.5498 17.3125 32.5001ZM22 32.4541L20.1709 30.6251L28.375 22.4201L30.2041 24.2501L22 32.4541ZM33.3822 21.0719L31 23.4541L26.5459 19.0001L28.9281 16.6169C28.9455 16.5995 28.9662 16.5857 28.989 16.5762C29.0117 16.5668 29.0361 16.5619 29.0608 16.5619C29.0854 16.5619 29.1098 16.5668 29.1326 16.5762C29.1553 16.5857 29.176 16.5995 29.1934 16.6169L33.3822 20.8066C33.3996 20.824 33.4135 20.8447 33.4229 20.8675C33.4323 20.8902 33.4372 20.9146 33.4372 20.9393C33.4372 20.9639 33.4323 20.9883 33.4229 21.0111C33.4135 21.0338 33.3996 21.0545 33.3822 21.0719Z"
              fill="black"
            />
          </svg>
        </div>
      </div>

      {isOpen && (
        <div>
          {/* Category Dropdown */}
          {type === 'imbottito' && !customConfig?.customCollection && (
            <div className="ay-mt-4">
              <select
                value={selectedCategory}
                onChange={(e) => handleCategoryChange(e.target.value)}
                className="ay-border ay-rounded-none ay-boder-black ay-w-full ay-p-4"
              >
                <option value="">TUTTE LE CATEGORIE</option>
                {getUniqueCategories(materials).map((category) => (
                  <option key={category} value={category}>
                    {category.toUpperCase()}
                  </option>
                ))}
              </select>
            </div>
          )}

          {customConfig && customConfig.customCollection ? (

            <div className="ay-grid  ay-gap-4 ay-mt-4">{
              // Render collections and their materials based on customCollection
              filteredMaterials
                .filter((material) => customConfig.customCollection.includes(material.collection))
                .map((material) => (
                  <CollectionItem
                    key={`${material.category}-${material.collection}`}
                    material={material}
                    isSelected={selected === material}
                    onToggle={handleCollectionToggle}
                    isOpen={openCollections[material.collection]}
                    onMaterialChange={handleMaterialChange}
                  />
                ))
            }  </div>) : (

            // Render individual materials if customMaterial is provided, or render collections and their materials otherwise
            customConfig && customConfig.customMaterial ? (
              <div className="ay-grid ay-grid-cols-3 lg:ay-grid-cols-4  ay-gap-4 ay-mt-4">{

                filteredMaterials
                  .flatMap((material) => {
                    return material.items;
                  })
                  .filter((item) => {
                    const itemSlug = item.slug;
                    const isIncluded = customConfig.customMaterial.includes(itemSlug);
                    return isIncluded;
                  })
                  .map((item) => (
                    <CollectionItem
                      key={item.slug}
                      language={language}
                      material={{ items: [item] }}
                      isSelected={selectedMaterial === item}
                      onMaterialChange={handleMaterialChange}
                      customConfig={customConfig}
                    />
                  ))
              }</div>) : (
              filteredMaterials.map((material) => (
                <div className="ay-grid  ay-gap-4 ay-mt-4">
                  {
                  <CollectionItem
                    key={`${material.category}-${material.collection}`}
                    material={material}
                    language={language}
                    isSelected={selected.slug}
                    onToggle={handleCollectionToggle}
                    isOpen={openCollections[material.collection]}
                    onMaterialChange={handleMaterialChange}
                  />
                  
                }</div>))
            )
          )}
        </div>
      )}
    </div>
  );
}

function CollectionItem({ material, isSelected, onToggle, isOpen, onMaterialChange, customConfig, language }) {
  return (
    <div className={`${customConfig ? 'ay-col-span-1' : 'ay-col-span-2'} ay-flex ay-flex-col`}>
      {customConfig ? (
        <div className="">
          {customConfig.customMaterial.map((customItemSlug) => {
            const matchingMaterial = material.items.find(item => item.slug === customItemSlug);

            return matchingMaterial ? (
              <div
                key={customItemSlug}
                className={`material-item ay-group ay-relative ${isSelected === matchingMaterial ? 'ay-active' : ''
                  }`}
                onClick={() => onMaterialChange(matchingMaterial)}
              >
                <div className="ay-overflow-hidden ay-rounded-none">
                  <img
                    className="ay-aspect-square ay-w-full ay-rounded-none ay-object-cover group-hover:ay-border ay-border-gray-300 group-hover:ay-scale-125 ay-transition-transform"
                    src={matchingMaterial.thumbnail || placeholder}
                    alt={language === 'it' ? matchingMaterial.name.it : matchingMaterial.name.en}
                    style={{ transformOrigin: 'center center' }}
                  />
                </div>
                <p className={`ay-text-sm ay-mt-3.5 ${isSelected ? 'ay-font-bold' : 'group-hover:ay-font-bold'}`}>{language === 'it' ? matchingMaterial.name.it : matchingMaterial.name.en}</p>
              </div>
            ) : null;
          })}
        </div>
      ) : (
        <div>
          <div
            onClick={() => onToggle(material.collection)}
          >
            <div className="ay-border-b ay-border-gray-300 w-full ay-py-1.5">
              <h2 className={`ay-text-md ay-flex ay-justify-between`}>
                {material.collection.toUpperCase() === 'LANA' ? (language === 'it' ? 'LANA' : 'WOOL') :
                  material.collection.toUpperCase() === 'COTONE' ? (language === 'it' ? 'COTONE' : 'COTTON') :
                    material.collection.toUpperCase() === 'VELLUTO' ? (language === 'it' ? 'VELLUTO' : 'VELVET') :
                      material.collection.toUpperCase() === 'ECOPELLE' ? (language === 'it' ? 'ECOPELLE' : 'FAUX LEATHER') :
                        material.collection.toUpperCase()
                }
              </h2>
            </div>
          </div>
          <div className="ay-category-items-grid ay-grid ay-grid-cols-3 lg:ay-grid-cols-4 ay-gap-4 ay-mt-2.5">
            {material.items.map((item) => (
              <div
                key={item.slug}
                className={`ay-material-item ay-group ay-relative ${isSelected === item ? 'ay-active' : ''
                  }`}
                onClick={() => onMaterialChange(item)}
              >
                <div className="ay-overflow-hidden ay-rounded-none ">
                  <img
                    className="ay-aspect-square ay-w-full ay-rounded-none ay-object-cover group-hover:ay-border   group-hover:ay-scale-125 ay-transition-transform"
                    src={item.thumbnail || placeholder}
                    alt={item.name.it}
                    style={{ transformOrigin: 'center center' }}
                  />
                </div>
                <p className={`ay-text-sm ay-mt-3.5 ${isSelected === item.slug ? 'ay-font-bold' : 'group-hover:ay-font-bold'}`}>{item.name.it}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export { Accordion }; 