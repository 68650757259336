import React, { useEffect, useState } from 'react';
import Player from './Player';
import { Accordion } from './Accordion';
import { material, defaultRivestimento, defaultStruttura,  switchConfiguration } from './config';
import struttura from './collections/struttura';
import products from './products';
import logo from './assets/SIPA_logo.svg';
import icon_arrows_out from './assets/arrowsOut.svg';
import FamilyAccordion from './FamilyAccordion';

function App({ productId, groupProducts, language }) {
  const [loading, setLoading] = useState(true);
  const [selectedProductCode, setSelectedProductCode] = useState(productId);
  const [selectedMaterial, setSelectedMaterial] = useState(defaultRivestimento);
  const [selectedFamily, setSelectedFamily] = useState(null);
  const [selectedRivestimento, setSelectedRivestimento] = useState(defaultRivestimento);
  const [selectedStruttura, setSelectedStruttura] = useState(defaultStruttura);
  const [isAccordionHidden, setIsAccordionHidden] = useState(false);
  const [configurableMaterialOnClick, setConfigurableMaterialOnClick] = useState(null);

  let available_products = products;
  const toggleAccordionVisibility = () => {
    setIsAccordionHidden((prevState) => !prevState);
    setTimeout(() => {
      emViewers['emersyaIframe'].resize()

    }, (150))
  };
  console.log(groupProducts, language)

  const handleFamilyChange = (newFamilyName) => {
    setSelectedFamily(newFamilyName);
  };
  // Get the first product if found
  const selectedProduct = products.find((product) => product.code === selectedProductCode) || {};


  if (groupProducts == 1) {
    available_products = products.filter(el => el.famiglia === selectedProduct.famiglia);

    console.log(available_products)
  }


  const setDefaultMaterials = (projectData) => {
    // Set default rivestimento
    const defaultRivestimentoSlug = projectData.materialTrees.CUSCINO || '';
    const defaultRivestimentoMaterial = material.flatMap((collection) => collection.items).find((item) => item.slug === defaultRivestimentoSlug.toLowerCase());
    if (defaultRivestimentoMaterial) {
      defaultRivestimento.slug = defaultRivestimentoSlug;
      defaultRivestimento.name = defaultRivestimentoMaterial.name;
      defaultRivestimento.thumbnail = defaultRivestimentoMaterial.thumbnail;

    }

    // Set default struttura
    const defaultStrutturaSlug = projectData.materialTrees.STRUTTURA;
    const defaultStrutturaMaterial = struttura.flatMap((collection) => collection.items).find((item) => item.slug === defaultStrutturaSlug.toLowerCase());

    if (defaultStrutturaMaterial) {
      defaultStruttura.slug = defaultStrutturaSlug;
      defaultStruttura.name = defaultStrutturaMaterial.name;
      defaultStruttura.thumbnail = defaultStrutturaMaterial.thumbnail;

    }

    setLoading(false);
  };

  useEffect(() => {
    function initializeEmersyaAPI() {
      const emersyaViewer = emViewers['emersyaIframe'];

      emersyaViewer.addEventListener('onStateChange', (data) => {
        if (data.viewerState === 'loaded') {
          emersyaViewer.getCurrentGlobalConfiguration().then((data) => {
            const currentProject = Object.keys(data.projectsData)[0];
            const projectData = data.projectsData[currentProject];
            setDefaultMaterials(projectData);

            console.log(projectData)

          });
        }
      });
    }

    document.addEventListener('emersyaViewerInitialized', initializeEmersyaAPI, false);

    // Cleanup function
    return () => {
      document.removeEventListener('emersyaViewerInitialized', initializeEmersyaAPI);
    };
  }, []);

  const handleProductChange = (newProductCode) => {
    if (!newProductCode) {
      return; // If the selected value is empty, do nothing
    }

    /*  setLoading(true); */
    setSelectedProductCode(newProductCode);
    const newProduct = products.find((product) => product.code === newProductCode);
    if (newProduct) {

      setSelectedRivestimento(defaultRivestimento);
      setSelectedStruttura(defaultStruttura);
      setConfigurableMaterialOnClick(null)
    }
  };

  const resetRivestimentoMaterial = () => {
    setSelectedMaterial(defaultRivestimento);
  };

  const handleMaterialChange = (newMaterial) => {
    if (configurableMaterialOnClick != null) {
      setSelectedRivestimento(newMaterial);
      switchConfiguration(newMaterial, configurableMaterialOnClick);
    } else {
      setSelectedRivestimento(newMaterial);
      switchConfiguration(newMaterial, 'CUSCINO');
    }
    emViewers['emersyaIframe'].setHighlight({ configurableMaterials: [''] });
  };

  const handleWoodChange = (newMaterial) => {
    setSelectedStruttura(newMaterial);
    switchConfiguration(newMaterial, 'STRUTTURA');
  };

  let base_url_preventivo = {
    it: 'https://www.sipasedie.it/richiedi-preventivo/',
    en: 'https://www.sipasedie.it/en/richiedi-preventivo/'
  };

  let url_preventivo = `${base_url_preventivo[language]}?product=${selectedProduct.name[language]}&structure=${selectedStruttura.name[language]}${selectedProduct.hasFabric ? `&stuffing=${selectedRivestimento.slug}` : ""}`;

  return <>
    {loading && (
      <div className="ay-w-full ay-h-screen ay-bg-white ay-opacity-95 ay-fixed ay-top-0 ay-z-50 ay-flex ay-justify-center ay-items-center ay-text-3xl" id="loader">
        <img src={logo} alt="Logo dieffebi" className="ay-animate-scale-pulse ay-w-80" />
      </div>
    )}

    <div className="ay-flex ay-flex-col md:ay-flex-row ay-h-screen ay-overflow-hidden">
      <div className={`ay-relative  ${isAccordionHidden ? 'ay-w-full' : 'ay-w-full md:ay-w-4/6'} ay-transition-all duration-500 ay-ease-in-out`}>

        <Player selectedProductCode={selectedProductCode} />


        <button
          onClick={toggleAccordionVisibility}
          className="ay-absolute ay-right-10 ay-top-10  ay-text-3xl ay-font-bold ay-focus:ay-outline-none"
        > <img  className="hover:ay-cursor-pointer ay-w-8" src={icon_arrows_out} alt=""/>
          {/* {isAccordionHidden ? '<' : '>'} */}
        </button>
      </div>
      <div
        className={`ay-p-5  ay-overflow-auto ${isAccordionHidden ? 'ay-w-0' : 'ay-w-full md:ay-w-2/6'} 
        ay-transition-all duration-500 ay-ease-in-out `}
        style={{ display: isAccordionHidden ? 'none' : 'block' }}
      >
        <div className='ay-flex ay-flex-col ay-h-full ay-justify-between'>



          <div>
            
          <FamilyAccordion
            products={products}
            language={language}
            selectedProduct={selectedProduct}
            onProductChange={handleProductChange}
            selectedFamily={selectedFamily}
            title_en={selectedProduct.name.it.indexOf('tavolo') !== -1 ? 'table type' : 'seating type'}
            title={selectedProduct.name.it.indexOf('tavolo') !== -1 ? 'tipologia di tavolo' : 'tipologia di seduta'}
          />
            {selectedProduct.hasWood && (
              <Accordion
                title={language === 'it' ? 'essenze e colori' : 'essences and colors'}
                language={language}
                materials={struttura}
                selected={selectedStruttura}
                onChange={handleWoodChange}
                customConfig={
                  selectedProduct.customMaterial
                    ? {
                      customMaterial: selectedProduct.customMaterial,
                    }
                    : null
                }
                defaultRivestimento={defaultStruttura}
                type="STRUTTURA"
              />
            )}
            {selectedProduct.hasFabric && (
              <Accordion
                title={language === 'it' ? 'colore imbottitura' : 'upholstery color'}
                language={language}
                materials={material}
                selected={selectedRivestimento}
                onChange={handleMaterialChange}
                customConfig={
                  selectedProduct.customCollection && selectedProduct.customMaterial
                    ? {
                      customCollection: selectedProduct.customCollection,
                      customMaterial: selectedProduct.customMaterial,
                    }
                    : null
                }
                defaultRivestimento={defaultRivestimento}
                type="CUSCINO"
              />
            )}


          </div>
          <div className=" ay-h-auto ">
            <a href={url_preventivo} className="ay-w-100 ay-block ay-text-center ay-border ay-border-gray-300 hover:ay-bg-black hover:ay-text-white hover:ay-underline ay-p-6 ay-mb-4 ay-hover:bg-black ay-hover:text-white">
              {language === "en" ? 'Request a quote' : 'Richiedi un preventivo'}
            </a>
          </div>
        </div>

      </div>
    </div>
  </>

}

export default App