// config.js
import materiali from './materials-stuff.js';

export const material = materiali;
export const defaultRivestimento = {
  slug: '',
  name: '',
  thumbnail: '',
};

// If found, set defaultStruttura to the material object
export const defaultStruttura =  {
  slug: '',
  name: '',
  thumbnail: '',
};

export let defaultFinitura =  {
  slug: '',
  name: '',
  thumbnail: '',
};

export let configuration = {
  rivestimento: defaultRivestimento.slug,
};
export let appliedConfiguration = `${configuration.rivestimento}`;

export function switchConfiguration(configurationName, type) {
  let configurableMaterial = null
  if (type === 'CUSCINO') {
    configuration.rivestimento = configurationName;
    configurableMaterial = 'CUSCINO'
    appliedConfiguration = `${configuration.rivestimento.slug}`
  } else if (type === 'STRUTTURA') {
    configuration.struttura = configurationName;
    configurableMaterial = 'STRUTTURA'
    appliedConfiguration = `${configuration.struttura.name.it}`

  }

    // Update appliedConfiguration with configuration values

  console.log(appliedConfiguration) 
  console.log(type);

  emViewers['emersyaIframe'].setMaterials({
    materials: [{ materialVariation: appliedConfiguration.toUpperCase(), configurableMaterial }],
  

  })
}

