
import abeNat2x from '../assets/essenze/abe-nat-2x.jpg';
import abeNat from '../assets/essenze/abe-nat.jpg';
import abeRov2x from '../assets/essenze/abe-rov-2x.jpg';
import abeRov from '../assets/essenze/abe-rov.jpg';
import abeSmo2x from '../assets/essenze/abe-smo-2x.jpg';
import abeSmo from '../assets/essenze/abe-smoke.jpg';
import fraAra2x from '../assets/essenze/fra-ara-2x.jpg';
import fraAra from '../assets/essenze/fra-ara.jpg';
import fraBia2x from '../assets/essenze/fra-bia-2x.jpg';
import fraBia from '../assets/essenze/fra-bia.jpg';
import fraBlu2x from '../assets/essenze/fra-blu-2x.jpg';
import fraBlu from '../assets/essenze/fra-blu.jpg';
import fraBor2x from '../assets/essenze/fra-bor-2x.jpg';
import fraBor from '../assets/essenze/fra-bor.jpg';
import fraGia2x from '../assets/essenze/fra-gia-2x.jpg';
import fraGia from '../assets/essenze/fra-gia.jpg';
import fraGre2x from '../assets/essenze/fra-gre-2x.jpg';
import fraGre from '../assets/essenze/fra-gre.jpg';
import fraNat2x from '../assets/essenze/fra-nat-2x.jpg';
import fraNat from '../assets/essenze/fra-nat.jpg';
import fraNer2x from '../assets/essenze/fra-ner-2x.jpg';
import fraNer from '../assets/essenze/fra-ner.jpg';
import fraNoc2x from '../assets/essenze/fra-noc-2x.jpg';
import fraNoc from '../assets/essenze/fra-noc.jpg';
import fraRos2x from '../assets/essenze/fra-ros-2x.jpg';
import fraRos from '../assets/essenze/fra-ros.jpg';
import fraRov2x from '../assets/essenze/fra-rov-2x.jpg';
import fraRov from '../assets/essenze/fra-rov.jpg';
import fraRss2x from '../assets/essenze/fra-rss-2x.jpg';
import fraRss from '../assets/essenze/fra-rss.jpg';
import fraSmo2x from '../assets/essenze/fra-smo-2x.jpg';
import fraSmo from '../assets/essenze/fra-smo.jpg';
import rovNat2x from '../assets/essenze/rov-nat-2x.jpg';
import rovNat from '../assets/essenze/rov-nat.jpg';
import fraVer2x from '../assets/essenze/fra-ver-2x.jpg';
import fraVer from '../assets/essenze/fra-ver.jpg';


const struttura = [
  {
    category: 'cat. A',
    collection: 'frassino',
    items: [
      {
        name: {
          it: 'Frassino Naturale',
          en: 'Ash Varnished Natural',
        },
        slug: 'frassino naturale',
        hex: '#D2C9B2',
        thumbnail2x: fraNat2x,
        thumbnail: fraNat
      },
      {
        name: {
          it: 'Frassino Giallo',
          en: 'Ash Lacquered Yellow',
        },
        slug: 'frassino giallo',
        hex: '#E7D36F',
        thumbnail2x: fraGia2x,
        thumbnail: fraGia
      },
      {
        name: {
          it: 'Frassino Arancio',
          en: 'Ash Lacquered Orange',
        },
        slug: 'frassino arancio',
        hex: '#FFA500',
        thumbnail2x: fraAra2x,
        thumbnail: fraAra
      },
      {
        name: {
          it: 'Frassino Rosa',
          en: 'Ash Lacquered Pink',
        },
        slug: 'frassino rosa',
        hex: '#FFC0CB',
        thumbnail2x: fraRos2x,
        thumbnail: fraRos
      },
      {
        name: {
          it: 'Frassino Bordeaux',
          en: 'Ash Lacquered Bordeaux',
        },
        slug: 'frassino bordeaux',
        hex: '#800020',
        thumbnail2x: fraBor2x,
        thumbnail: fraBor
      },
      {
        name: {
          it: 'Frassino Blu',
          en: 'Ash Lacquered Blue',
        },
        slug: 'frassino blu',
        hex: '#0000FF',
        thumbnail2x: fraBlu2x,
        thumbnail: fraBlu
      },
      {
        name: {
          it: 'Frassino Verde',
          en: 'Ash Lacquered Green',
        },
        slug: 'frassino verde',
        hex: '#008000',
        thumbnail2x: fraVer2x,
        thumbnail: fraVer
      },
      {
        name: {
          it: 'Frassino Rosso',
          en: 'Ash Lacquered Red',
        },
        slug: 'frassino rosso',
        hex: '#FF0000',
        thumbnail2x: fraRss2x,
        thumbnail: fraRss
      },
      {
        name: {
          it: 'Frassino Bianco',
          en: 'Ash Lacquered White',
        },
        slug: 'frassino bianco',
        hex: '#FFFFFF',
        thumbnail2x: fraBia2x,
        thumbnail: fraBia
      },
      {
        name: {
          it: 'Frassino Greige',
          en: 'Ash Lacquered Greige',
        },
        slug: 'frassino greige',
        hex: '#BDBAA7',
        thumbnail2x: fraGre2x,
        thumbnail: fraGre
      },
      {
        name: {
          it: 'Frassino Nero',
          en: 'Ash Lacquered Black',
        },
        slug: 'frassino nero',
        hex: '#000000',
        thumbnail2x: fraNer2x,
        thumbnail: fraNer
      },
      {
        name: {
          it: 'Frassino Tinta Rovere Naturale',
          en: 'Ash Stained Oak',
        },
        slug: 'frassino tinta rovere naturale',
        hex: '#836953',
        thumbnail2x: fraRov2x,
        thumbnail: fraRov
      },
      {
        name: {
          it: 'Frassino Smoke',
          en: 'Ash Stained Smoke',
        },
        slug: 'frassino smoke',
        hex: '#A8A8A8',
        thumbnail2x: fraSmo2x,
        thumbnail: fraSmo
      },
      {
        name: {
          it: 'Frassino Tinta Noce Wd59',
          en: 'Ash Stained Walnut',
        },
        slug: 'frassino tinta noce wd59',
        hex: '#5D3B1A',
        thumbnail2x: fraNoc2x,
        thumbnail: fraNoc
      },
      {
        name: {
          it: 'Abete Naturale',
          en: 'Fir Varnished Natural',
        },
        slug: 'abete naturale',
        hex: '#D2C9B2',
        thumbnail2x: abeNat2x,
        thumbnail: abeNat
      },
      {
        name: {
          it: 'Abete  Giallo',
          en: 'Fir Lacquered Yellow',
        },
        slug: 'abete giallo',
        hex: '#E7D36F',
        thumbnail2x: fraGia2x,
        thumbnail: fraGia
      },
      {
        name: {
          it: 'Abete  Arancio',
          en: 'Fir Lacquered Orange',
        },
        slug: 'abete arancio',
        hex: '#FFA500',
        thumbnail2x: fraAra2x,
        thumbnail: fraAra
      },
      {
        name: {
          it: 'Abete  Rosa',
          en: 'Fir Lacquered Pink',
        },
        slug: 'abete rosa',
        hex: '#FFC0CB',
        thumbnail2x: fraRos2x,
        thumbnail: fraRos
      },
      {
        name: {
          it: 'Abete  Bordeaux',
          en: 'Fir Lacquered Bordeaux',
        },
        slug: 'abete bordeaux',
        hex: '#800020',
        thumbnail2x: fraBor2x,
        thumbnail: fraBor
      },
      {
        name: {
          it: 'Abete  Blu',
          en: 'Fir Lacquered Blue',
        },
        slug: 'abete blu',
        hex: '#0000FF',
        thumbnail2x: fraBlu2x,
        thumbnail: fraBlu
      },
      {
        name: {
          it: 'Abete  Verde',
          en: 'Fir Lacquered Green',
        },
        slug: 'abete verde',
        hex: '#008000',
        thumbnail2x: fraVer2x,
        thumbnail: fraVer
      },
      {
        name: {
          it: 'Abete  Rosso',
          en: 'Fir Lacquered Red',
        },
        slug: 'abete rosso',
        hex: '#FF0000',
        thumbnail2x: fraRss2x,
        thumbnail: fraRss
      },
      {
        name: {
          it: 'Abete  Bianco',
          en: 'Fir Lacquered White',
        },
        slug: 'abete bianco',
        hex: '#FFFFFF',
        thumbnail2x: fraBia2x,
        thumbnail: fraBia
      },
      {
        name: {
          it: 'Abete  Greige',
          en: 'Fir Lacquered Greige',
        },
        slug: 'abete greige',
        hex: '#BDBAA7',
        thumbnail2x: fraGre2x,
        thumbnail: fraGre
      },
      {
        name: {
          it: 'Abete  Nero',
          en: 'Fir Lacquered Black',
        },
        slug: 'abete nero',
        hex: '#000000',
        thumbnail2x: fraNer2x,
        thumbnail: fraNer
      },
      {
        name: {
          it: 'Abete Tinta Rovere Naturale',
          en: 'Fir Stained Oak',
        },
        slug: 'abete tinta rovere naturale',
        hex: '#836953',
        thumbnail2x: abeRov2x,
        thumbnail: abeRov
      },
      {
        name: {
          it: 'Abete  Smoke',
          en: 'Fir Stained Smoke',
        },
        slug: 'abete smoke',
        hex: '#A8A8A8',
        thumbnail2x: abeSmo2x,
        thumbnail: abeSmo
      },
      {
        name: {
          it: 'Abete Tinta Noce Wd59',
          en: 'Fir Stained Walnut',
        },
        slug: 'abete tinta noce wd59',
        hex: '#5D3B1A',
        thumbnail2x: fraNoc2x,
        thumbnail: fraNoc
      }
    ]
  }

]
export default struttura;