
import aida1032x from './assets/imbottiti/aida-103-2x.jpg';
import aida103 from './assets/imbottiti/aida-103.jpg';
import aida3012x from './assets/imbottiti/aida-301-2x.jpg';
import aida301 from './assets/imbottiti/aida-301.jpg';
import aida3022x from './assets/imbottiti/aida-302-2x.jpg';
import aida302 from './assets/imbottiti/aida-302.jpg';
import aida4002x from './assets/imbottiti/aida-400-2x.jpg';
import aida400 from './assets/imbottiti/aida-400.jpg';
import aida4022x from './assets/imbottiti/aida-402-2x.jpg';
import aida402 from './assets/imbottiti/aida-402.jpg';
import aida4072x from './assets/imbottiti/aida-407-2x.jpg';
import aida407 from './assets/imbottiti/aida-407.jpg';
import aida5012x from './assets/imbottiti/aida-501-2x.jpg';
import aida501 from './assets/imbottiti/aida-501.jpg';
import aida5022x from './assets/imbottiti/aida-502-2x.jpg';
import aida502 from './assets/imbottiti/aida-502.jpg';
import aida6012x from './assets/imbottiti/aida-601-2x.jpg';
import aida601 from './assets/imbottiti/aida-601.jpg';
import aida8042x from './assets/imbottiti/aida-804-2x.jpg';
import aida804 from './assets/imbottiti/aida-804.jpg';
import aida8082x from './assets/imbottiti/aida-808-2x.jpg';
import aida808 from './assets/imbottiti/aida-808.jpg';
import aida9022x from './assets/imbottiti/aida-902-2x.jpg';
import aida902 from './assets/imbottiti/aida-902.jpg';
import ganesha2002x from './assets/imbottiti/ganesha-200-2x.jpg';
import ganesha200 from './assets/imbottiti/ganesha-200.jpg';
import ganesha3012x from './assets/imbottiti/ganesha-301-2x.jpg';
import ganesha301 from './assets/imbottiti/ganesha-301.jpg';
import ganesha4002x from './assets/imbottiti/ganesha-400-2x.jpg';
import ganesha400 from './assets/imbottiti/ganesha-400.jpg';
import ganesha4022x from './assets/imbottiti/ganesha-402-2x.jpg';
import ganesha402 from './assets/imbottiti/ganesha-402.jpg';
import ganesha4102x from './assets/imbottiti/ganesha-410-2x.jpg';
import ganesha410 from './assets/imbottiti/ganesha-410.jpg';
import ganesha5032x from './assets/imbottiti/ganesha-503-2x.jpg';
import ganesha503 from './assets/imbottiti/ganesha-503.jpg';
import ganesha6082x from './assets/imbottiti/ganesha-608-2x.jpg';
import ganesha608 from './assets/imbottiti/ganesha-608.jpg';
import ganesha8072x from './assets/imbottiti/ganesha-807-2x.jpg';
import ganesha807 from './assets/imbottiti/ganesha-807.jpg';
import horo0012x from './assets/imbottiti/horo-001-2x.jpg';
import horo001 from './assets/imbottiti/horo-001.jpg';
import horo0032x from './assets/imbottiti/horo-003-2x.jpg';
import horo003 from './assets/imbottiti/horo-003.jpg';
import horo1002x from './assets/imbottiti/horo-100-2x.jpg';
import horo100 from './assets/imbottiti/horo-100.jpg';
import horo1032x from './assets/imbottiti/horo-103-2x.jpg';
import horo103 from './assets/imbottiti/horo-103.jpg';
import horo4012x from './assets/imbottiti/horo-401-2x.jpg';
import horo401 from './assets/imbottiti/horo-401.jpg';
import horo4072x from './assets/imbottiti/horo-407-2x.jpg';
import horo407 from './assets/imbottiti/horo-407.jpg';
import horo5002x from './assets/imbottiti/horo-500-2x.jpg';
import horo500 from './assets/imbottiti/horo-500.jpg';
import horo5012x from './assets/imbottiti/horo-501-2x.jpg';
import horo501 from './assets/imbottiti/horo-501.jpg';
import horo5032x from './assets/imbottiti/horo-503-2x.jpg';
import horo503 from './assets/imbottiti/horo-503.jpg';
import horo6002x from './assets/imbottiti/horo-600-2x.jpg';
import horo600 from './assets/imbottiti/horo-600.jpg';
import horo7012x from './assets/imbottiti/horo-701-2x.jpg';
import horo701 from './assets/imbottiti/horo-701.jpg';
import horo8032x from './assets/imbottiti/horo-803-2x.jpg';
import horo803 from './assets/imbottiti/horo-803.jpg';
import horo8052x from './assets/imbottiti/horo-805-2x.jpg';
import horo805 from './assets/imbottiti/horo-805.jpg';
import horo9022x from './assets/imbottiti/horo-902-2x.jpg';
import horo902 from './assets/imbottiti/horo-902.jpg';
import magnum0062x from './assets/imbottiti/magnum-006-2x.jpg';
import magnum006 from './assets/imbottiti/magnum-006.jpg';
import magnum3102x from './assets/imbottiti/magnum-310-2x.jpg';
import magnum310 from './assets/imbottiti/magnum-310.jpg';
import magnum7062x from './assets/imbottiti/magnum-706-2x.jpg';
import magnum706 from './assets/imbottiti/magnum-706.jpg';
import nordwool2002x from './assets/imbottiti/nord-wool-200-2x.jpg';
import nordwool200 from './assets/imbottiti/nord-wool-200.jpg';
import nordwool3032x from './assets/imbottiti/nord-wool-303-2x.jpg';
import nordwool303 from './assets/imbottiti/nord-wool-303.jpg';
import nordwool4022x from './assets/imbottiti/nord-wool-402-2x.jpg';
import nordwool402 from './assets/imbottiti/nord-wool-402.jpg';
import nordwool4032x from './assets/imbottiti/nord-wool-403-2x.jpg';
import nordwool403 from './assets/imbottiti/nord-wool-403.jpg';
import nordwool4062x from './assets/imbottiti/nord-wool-406-2x.jpg';
import nordwool406 from './assets/imbottiti/nord-wool-406.jpg';
import nordwool6002x from './assets/imbottiti/nord-wool-600-2x.jpg';
import nordwool600 from './assets/imbottiti/nord-wool-600.jpg';
import nordwool8012x from './assets/imbottiti/nord-wool-801-2x.jpg';
import nordwool801 from './assets/imbottiti/nord-wool-801.jpg';
import opera1012x from './assets/imbottiti/opera-101-2x.jpg';
import opera101 from './assets/imbottiti/opera-101.jpg';
import opera2012x from './assets/imbottiti/opera-201-2x.jpg';
import opera201 from './assets/imbottiti/opera-201.jpg';
import opera3002x from './assets/imbottiti/opera-300-2x.jpg';
import opera300 from './assets/imbottiti/opera-300.jpg';
import opera3032x from './assets/imbottiti/opera-303-2x.jpg';
import opera303 from './assets/imbottiti/opera-303.jpg';
import opera4032x from './assets/imbottiti/opera-403-2x.jpg';
import opera403 from './assets/imbottiti/opera-403.jpg';
import opera4042x from './assets/imbottiti/opera-404-2x.jpg';
import opera404 from './assets/imbottiti/opera-404.jpg';
import opera8002x from './assets/imbottiti/opera-800-2x.jpg';
import opera800 from './assets/imbottiti/opera-800.jpg';



const materiali = [
    
        {
            category: 'cat. A',
collection: "Lana",
            items: [
              {
                name: {
                  it: "Nord Wool 402",
                  en: "Nord Wool 402",
                },
                slug: "nord wool 402",
                "thumbnail2x": nordwool4022x,
                "thumbnail": nordwool402
              },
              {
                name: {
                  it: "Nord Wool 403",
                  en: "Nord Wool 403",
                },
                slug: "nord wool 403",
                "thumbnail2x": nordwool4032x,
                "thumbnail": nordwool403
              },
              {
                name: {
                  it: "Nord Wool 406",
                  en: "Nord Wool 406",
                },
                slug: "nord wool 406",
                "thumbnail2x": nordwool4062x,
                "thumbnail": nordwool406
              },
              {
                name: {
                  it: "Nord Wool 200",
                  en: "Nord Wool 200",
                },
                slug: "nord wool 200",
                "thumbnail2x": nordwool2002x,
                "thumbnail": nordwool200
              },
              {
                name: {
                  it: "Nord Wool 600",
                  en: "Nord Wool 600",
                },
                slug: "nord wool 600",
                "thumbnail2x": nordwool6002x,
                "thumbnail": nordwool600
              },
              {
                name: {
                  it: "Nord Wool 801",
                  en: "Nord Wool 801",
                },
                slug: "nord wool 801",
                "thumbnail2x": nordwool8012x,
                "thumbnail": nordwool801
              },
              {
                name: {
                  it: "Nord Wool 303",
                  en: "Nord Wool 303",
                },
                slug: "nord wool 303",
                "thumbnail2x": nordwool3032x,
                "thumbnail": nordwool303
              },
            ],
          },
          {
            category: 'cat. A',
collection: "Cotone",
            items: [
              {
                name: {
                  it: "Opera 403",
                  en: "Opera 403",
                },
                slug: "opera 403",
                "thumbnail2x": opera4032x,
                "thumbnail": opera403
              },
              {
                name: {
                  it: "Opera 404",
                  en: "Opera 404",
                },
                slug: "opera 404",
                "thumbnail2x": opera4042x,
                "thumbnail": opera404
              },
              {
                name: {
                  it: "Opera 101",
                  en: "Opera 101",
                },
                slug: "opera 101",
                "thumbnail2x": opera1012x,
                "thumbnail": opera101
              },
              {
                name: {
                  it: "Opera 300",
                  en: "Opera 300",
                },
                slug: "opera 300",
                "thumbnail2x": opera3002x,
                "thumbnail": opera300
              },
              {
                name: {
                  it: "Opera 303",
                  en: "Opera 303",
                },
                slug: "opera 303",
                "thumbnail2x": opera3032x,
                "thumbnail": opera303
              },
              {
                name: {
                  it: "Opera 201",
                  en: "Opera 201",
                },
                slug: "opera 201",
                "thumbnail2x": opera2012x,
                "thumbnail": opera201
              },
              {
                name: {
                  it: "Opera 800",
                  en: "Opera 800",
                },
                slug: "opera 800",
                "thumbnail2x": opera8002x,
                "thumbnail": opera800
              },
              {
                name: {
                  it: "Horo 500",
                  en: "Horo 500",
                },
                slug: "horo 500",
                "thumbnail2x": horo5002x,
                "thumbnail": horo500
              },
              {
                name: {
                  it: "Horo 103",
                  en: "Horo 103",
                },
                slug: "horo 103",
                "thumbnail2x": horo1032x,
                "thumbnail": horo103
              },
              {
                name: {
                  it: "Horo 001",
                  en: "Horo 001",
                },
                slug: "horo 001",
                "thumbnail2x": horo0012x,
                "thumbnail": horo001
              },
              {
                name: {
                  it: "Horo 902",
                  en: "Horo 902",
                },
                slug: "horo 902",
                "thumbnail2x": horo9022x,
                "thumbnail": horo902
              },
              {
                name: {
                  it: "Horo 503",
                  en: "Horo 503",
                },
                slug: "horo 503",
                "thumbnail2x": horo5032x,
                "thumbnail": horo503
              },
              {
                name: {
                  it: "Horo 803",
                  en: "Horo 803",
                },
                slug: "horo 803",
                "thumbnail2x": horo8032x,
                "thumbnail": horo803
              },
              {
                name: {
                  it: "Horo 401",
                  en: "Horo 401",
                },
                slug: "horo 401",
                "thumbnail2x": horo4012x,
                "thumbnail": horo401
              },
              {
                name: {
                  it: "Horo 600",
                  en: "Horo 600",
                },
                slug: "horo 600",
                "thumbnail2x": horo6002x,
                "thumbnail": horo600
              },
              {
                name: {
                  it: "Horo 100",
                  en: "Horo 100",
                },
                slug: "horo 100",
                "thumbnail2x": horo1002x,
                "thumbnail": horo100
              },
              {
                name: {
                  it: "Horo 805",
                  en: "Horo 805",
                },
                slug: "horo 805",
                "thumbnail2x": horo8052x,
                "thumbnail": horo805
              },
              {
                name: {
                  it: "Horo 701",
                  en: "Horo 701",
                },
                slug: "horo 701",
                "thumbnail2x": horo7012x,
                "thumbnail": horo701
              },
              {
                name: {
                  it: "Horo 501",
                  en: "Horo 501",
                },
                slug: "horo 501",
                "thumbnail2x": horo5012x,
                "thumbnail": horo501
              },
              {
                name: {
                  it: "Horo 407",
                  en: "Horo 407",
                },
                slug: "horo 407",
                "thumbnail2x": horo4072x,
                "thumbnail": horo407
              },
              {
                name: {
                  it: "Horo 003",
                  en: "Horo 003",
                },
                slug: "horo 003",
                "thumbnail2x": horo0032x,
                "thumbnail": horo003
              },
            ]
          },
          {
            category: 'cat. A',
collection: "velluto",
            items: [
              {
                name: {
                  it: "Aida 502",
                  en: "Aida 502",
                },
                slug: "aida 502",
                "thumbnail2x": aida5022x,
                "thumbnail": aida502
              },
              {
                name: {
                  it: "Aida 301",
                  en: "Aida 301",
                },
                slug: "aida 301",
                "thumbnail2x": aida3012x,
                "thumbnail": aida301
              },
              {
                name: {
                  it: "Aida 902",
                  en: "Aida 902",
                },
                slug: "aida 902",
                "thumbnail2x": aida9022x,
                "thumbnail": aida902
              },
              {
                name: {
                  it: "Aida 407",
                  en: "Aida 407",
                },
                slug: "aida 407",
                "thumbnail2x": aida4072x,
                "thumbnail": aida407
              },
              {
                name: {
                  it: "Aida 808",
                  en: "Aida 808",
                },
                slug: "aida 808",
                "thumbnail2x": aida8082x,
                "thumbnail": aida808
              },
              {
                name: {
                  it: "Aida 400",
                  en: "Aida 400",
                },
                slug: "aida 400",
                "thumbnail2x": aida4002x,
                "thumbnail": aida400
              },
              {
                name: {
                  it: "Aida 402",
                  en: "Aida 402",
                },
                slug: "aida 402",
                "thumbnail2x": aida4022x,
                "thumbnail": aida402
              },
              {
                name: {
                  it: "Aida 804",
                  en: "Aida 804",
                },
                slug: "aida 804",
                "thumbnail2x": aida8042x,
                "thumbnail": aida804
              },
              {
                name: {
                  it: "Aida 601",
                  en: "Aida 601",
                },
                slug: "aida 601",
                "thumbnail2x": aida6012x,
                "thumbnail": aida601
              },
              {
                name: {
                  it: "Aida 103",
                  en: "Aida 103",
                },
                slug: "aida 103",
                "thumbnail2x": aida1032x,
                "thumbnail": aida103
              },
              {
                name: {
                  it: "Aida 302",
                  en: "Aida 302",
                },
                slug: "aida 302",
                "thumbnail2x": aida3022x,
                "thumbnail": aida302
              },
              {
                name: {
                  it: "Aida 501",
                  en: "Aida 501",
                },
                slug: "aida 501",
                "thumbnail2x": aida5012x,
                "thumbnail": aida501
              },
            ]
          },
          {
            category: 'cat. A',
collection: "ecopelle",
            items: [
              {
                name: {
                  it: "Magnum 310",
                  en: "Magnum 310",
                },
                slug: "magnum 310",
                "thumbnail2x": magnum3102x,
                "thumbnail": magnum310
              },
              {
                name: {
                  it: "Magnum 706",
                  en: "Magnum 706",
                },
                slug: "magnum 706",
                "thumbnail2x": magnum7062x,
                "thumbnail": magnum706
              },
              {
                name: {
                  it: "Magnum 006",
                  en: "Magnum 006",
                },
                slug: "magnum 006",
                "thumbnail2x": magnum0062x,
                "thumbnail": magnum006
              },
              {
                name: {
                  it: "Ganesha 410",
                  en: "Ganesha 410",
                },
                slug: "ganesha 410",
                "thumbnail2x": ganesha4102x,
                "thumbnail": ganesha410
              },
              {
                name: {
                  it: "Ganesha 402",
                  en: "Ganesha 402",
                },
                slug: "ganesha 402",
                "thumbnail2x": ganesha4022x,
                "thumbnail": ganesha402
              },
              {
                name: {
                  it: "Ganesha 400",
                  en: "Ganesha 400",
                },
                slug: "ganesha 400",
                "thumbnail2x": ganesha4002x,
                "thumbnail": ganesha400
              },
              {
                name: {
                  it: "Ganesha 807",
                  en: "Ganesha 807",
                },
                slug: "ganesha 807",
                "thumbnail2x": ganesha8072x,
                "thumbnail": ganesha807
              },
              {
                name: {
                  it: "Ganesha 608",
                  en: "Ganesha 608",
                },
                slug: "ganesha 608",
                "thumbnail2x": ganesha6082x,
                "thumbnail": ganesha608
              },
              {
                name: {
                  it: "Ganesha 301",
                  en: "Ganesha 301",
                },
                slug: "ganesha 301",
                "thumbnail2x": ganesha3012x,
                "thumbnail": ganesha301
              },
              {
                name: {
                  it: "Ganesha 503",
                  en: "Ganesha 503",
                },
                slug: "ganesha 503",
                "thumbnail2x": ganesha5032x,
                "thumbnail": ganesha503
              },
              {
                name: {
                  it: "Ganesha 200",
                  en: "Ganesha 200",
                },
                slug: "ganesha 200",
                "thumbnail2x": ganesha2002x,
                "thumbnail": ganesha200
              },
            ]
          }
        ]
        

export default materiali

