import arcoSedia from './assets/prodotti/arco-sedia.png';
import arcoSedia2x from './assets/prodotti/arco-sedia-2x.png';

import arcoImbottita from './assets/prodotti/arco-imbottita-2x.png';
import arcoImbottita2x from './assets/prodotti/arco-imbottita-2x.png';

import colonialSediaImbottita from './assets/prodotti/colonial-sedia-imbottita.png';
import colonialSediaImbottita2x from './assets/prodotti/colonial-sedia-imbottita-2x.png';

import colonialSedia from './assets/prodotti/colonial-sedia.png';
import colonialSedia2x from './assets/prodotti/colonial-sedia-2x.png';

import cosseTavoloD43 from './assets/prodotti/cosse-tavolo-d43.png';
import cosseTavoloD432x from './assets/prodotti/cosse-tavolo-d43-2x.png';

import cosseTavoloD54 from './assets/prodotti/cosse-tavolo-d54.png';
import cosseTavoloD542x from './assets/prodotti/cosse-tavolo-d54-2x.png';

import cutSediaImbottita from './assets/prodotti/cut-sedia-imbottita.png';
import cutSediaImbottita2x from './assets/prodotti/cut-sedia-imbottita-2x.png';

import cutSedia from './assets/prodotti/cut-sedia.png';
import cutSedia2x from './assets/prodotti/cut-sedia-2x.png';

import isaSediaImbottita from './assets/prodotti/isa-sedia-imbottita.png';
import isaSediaImbottita2x from './assets/prodotti/isa-sedia-imbottita-2x.png';

import jollySgabelloH from './assets/prodotti/jolly-sgabello-h.png';
import jollySgabelloH2x from './assets/prodotti/jolly-sgabello-h-2x.png';

import jollySgabelloL from './assets/prodotti/jolly-sgabello-l.png';
import jollySgabelloL2x from './assets/prodotti/jolly-sgabello-l-2x.png';

import jollySgabelloM from './assets/prodotti/jolly-sgabello-m.png';
import jollySgabelloM2x from './assets/prodotti/jolly-sgabello-m-2x.png';

import makiTavoloD45 from './assets/prodotti/maki-tavolo-d45.png';
import makiTavoloD452x from './assets/prodotti/maki-tavolo-d45-2x.png';

import milanoSediaImbottita from './assets/prodotti/milano-sedia-imbottita.png';
import milanoSediaImbottita2x from './assets/prodotti/milano-sedia-imbottita-2x.png';

import milanoSedia from './assets/prodotti/milano-sedia.png';
import milanoSedia2x from './assets/prodotti/milano-sedia-2x.png';

import monacoPoltronaImbottita from './assets/prodotti/monaco-poltrona-imbottita.png';
import monacoPoltronaImbottita2x from './assets/prodotti/monaco-poltrona-imbottita-2x.png';

import monacoPoltrona from './assets/prodotti/monaco-poltrona.png';
import monacoPoltrona2x from './assets/prodotti/monaco-poltrona-2x.png';

import monacoSediaImbottita from './assets/prodotti/monaco-sedia-imbottita.png';
import monacoSediaImbottita2x from './assets/prodotti/monaco-sedia-imbottita-2x.png';

import monacoSedia from './assets/prodotti/monaco-sedia.png';
import monacoSedia2x from './assets/prodotti/monaco-sedia-2x.png';

import nastyTavolo45 from './assets/prodotti/nasty-tavolo-45.png';
import nastyTavolo452x from './assets/prodotti/nasty-tavolo-45-2x.png';

import nastyTavoloD60 from './assets/prodotti/nasty-tavolo-d60.png';
import nastyTavoloD602x from './assets/prodotti/nasty-tavolo-d60-2x.png';

import onemetalTavolo60x60H from './assets/prodotti/onemetal-tavolo-60x60-h.png';
import onemetalTavolo60x60H2x from './assets/prodotti/onemetal-tavolo-60x60-h-2x.png';

import onemetalTavolo60x60M from './assets/prodotti/onemetal-tavolo-60x60-m.png';
import onemetalTavolo60x60M2x from './assets/prodotti/onemetal-tavolo-60x60-m-2x.png';

import onemetalTavolo70x70M from './assets/prodotti/onemetal-tavolo-70x70-m.png';
import onemetalTavolo70x70M2x from './assets/prodotti/onemetal-tavolo-70x70-m-2x.png';

import onewoodTavolo60x60M from './assets/prodotti/onewood-tavolo-60x60-m.png';
import onewoodTavolo60x60M2x from './assets/prodotti/onewood-tavolo-60x60-m-2x.png';

import onewoodTavolo70x70H from './assets/prodotti/onewood-tavolo-70x70-h.png';
import onewoodTavolo70x70H2x from './assets/prodotti/onewood-tavolo-70x70-h-2x.png';

import onewoodTavolo70x70M from './assets/prodotti/onewood-tavolo-70x70-m.png';
import onewoodTavolo70x70M2x from './assets/prodotti/onewood-tavolo-70x70-m-2x.png';

import onewoodTavoloD60H from './assets/prodotti/onewood-tavolo-d60-h.png';
import onewoodTavoloD60H2x from './assets/prodotti/onewood-tavolo-d60-h-2x.png';

import onewoodTavoloD60M from './assets/prodotti/onewood-tavolo-d60-m.png';
import onewoodTavoloD60M2x from './assets/prodotti/onewood-tavolo-d60-m-2x.png';

import onewoodTavoloD70M from './assets/prodotti/onewood-tavolo-d70-m.png';
import onewoodTavoloD70M2x from './assets/prodotti/onewood-tavolo-d70-m-2x.png';

import palmaSediaImbottita from './assets/prodotti/palma-sedia-imbottita.png';
import palmaSediaImbottita2x from './assets/prodotti/palma-sedia-imbottita-2x.png';

import palmaSedia from './assets/prodotti/palma-sedia.png';
import palmaSedia2x from './assets/prodotti/palma-sedia-2x.png';

import palmaSgabelloH61 from './assets/prodotti/palma-sgabello-h61.png';
import palmaSgabelloH612x from './assets/prodotti/palma-sgabello-h61-2x.png';

import palmaSgabelloH61Imbottito2x from './assets/prodotti/palma-sgabello-h61-imbottito-2x.png';
import palmaSgabelloH61Imbottito from './assets/prodotti/palma-sgabello-h61-imbottito.png';

import palmaSgabelloH75 from './assets/prodotti/palma-sgabello-h75.png';
import palmaSgabelloH752x from './assets/prodotti/palma-sgabello-h75-2x.png';

import palmaSgabelloH75Imbottito from './assets/prodotti/palma-sgabello-h75-imbottito.png';
import palmaSgabelloH75Imbottito2x from './assets/prodotti/palma-sgabello-h75-imbottito-2x.png';

import palmaSgabelloSchienaleH95Imbottito from './assets/prodotti/palma-sgabello-schienale-h95-imbottito.png';
import palmaSgabelloSchienaleH952x from './assets/prodotti/palma-sgabello-schienale-h95-2x.png';

import palmaSgabelloSchienaleH95 from './assets/prodotti/palma-sgabello-schienale-h95.png';
import palmaSgabelloSchienaleH95Imbottito2x from './assets/prodotti/palma-sgabello-schienale-h95-imbottito-2x.png';

import palmaSgabelloSchienaleH109Imbottito from './assets/prodotti/palma-sgabello-schienale-h109-imbottito.png';
import palmaSgabelloSchienaleH109Imbottito2x from './assets/prodotti/palma-sgabello-schienale-h109-imbottito-2x.png';

import palmaSgabelloSchienaleH109 from './assets/prodotti/palma-sgabello-schienale-h109.png';
import palmaSgabelloSchienaleH1092x from './assets/prodotti/palma-sgabello-schienale-h109-2x.png';

import popSediaImbottita from './assets/prodotti/pop-sedia-imbottita.png';
import popSediaImbottita2x from './assets/prodotti/pop-sedia-imbottita-2x.png';

import popSedia from './assets/prodotti/pop-sedia.png';
import popSedia2x from './assets/prodotti/pop-sedia-2x.png';

import popSgabelloImbottito from './assets/prodotti/pop-sgabello-imbottito.png';
import popSgabelloImbottito2x from './assets/prodotti/pop-sgabello-imbottito-2x.png';

import popSgabello from './assets/prodotti/pop-sgabello.png';
import popSgabello2x from './assets/prodotti/pop-sgabello-2x.png';

import tabletPoltronaImbottita from './assets/prodotti/tablet-poltrona-imbottita.png';
import tabletPoltronaImbottita2x from './assets/prodotti/tablet-poltrona-imbottita-2x.png';

import tabletPoltrona from './assets/prodotti/tablet-poltrona.png';
import tabletPoltrona2x from './assets/prodotti/tablet-poltrona-2x.png';

import tabletSediaImbottita from './assets/prodotti/tablet-sedia-imbottita.png';
import tabletSediaImbottita2x from './assets/prodotti/tablet-sedia-imbottita-2x.png';

import tabletSedia from './assets/prodotti/tablet-sedia.png';
import tabletSedia2x from './assets/prodotti/tablet-sedia-2x.png';

import tabletSgabelloH from './assets/prodotti/tablet-sgabello-h.png';
import tabletSgabelloH2x from './assets/prodotti/tablet-sgabello-h-2x.png';

import tabletSgabelloImbottitoH from './assets/prodotti/tablet-sgabello-imbottito-h.png';
import tabletSgabelloImbottitoH2x from './assets/prodotti/tablet-sgabello-imbottito-h-2x.png';

import tabletSgabelloImbottitoM from './assets/prodotti/tablet-sgabello-imbottito-m.png';
import tabletSgabelloImbottitoM2x from './assets/prodotti/tablet-sgabello-imbottito-m-2x.png';

import tabletSgabelloM from './assets/prodotti/tablet-sgabello-m.png';
import tabletSgabelloM2x from './assets/prodotti/tablet-sgabello-m-2x.png';

import tcoffeeSediaImbottita from './assets/prodotti/tcoffee-sedia-imbottita.png';
import tcoffeeSediaImbottita2x from './assets/prodotti/tcoffee-sedia-imbottita-2x.png';

import tcoffeeSedia from './assets/prodotti/tcoffee-sedia.png';
import tcoffeeSedia2x from './assets/prodotti/tcoffee-sedia-2x.png';

import tommySgabelloH from './assets/prodotti/tommy-sgabello-h.png';
import tommySgabelloH2x from './assets/prodotti/tommy-sgabello-h-2x.png';

import tommySgabelloImbottitoH from './assets/prodotti/tommy-sgabello-imbottito-h.png';
import tommySgabelloImbottitoH2x from './assets/prodotti/tommy-sgabello-imbottito-h-2x.png';

import tommySgabelloImbottitoL from './assets/prodotti/tommy-sgabello-imbottito-l.png';
import tommySgabelloImbottitoL2x from './assets/prodotti/tommy-sgabello-imbottito-l-2x.png';

import tommySgabelloImbottitoM from './assets/prodotti/tommy-sgabello-imbottito-m.png';
import tommySgabelloImbottitoM2x from './assets/prodotti/tommy-sgabello-imbottito-m-2x.png';

import tommySgabelloL from './assets/prodotti/tommy-sgabello-l.png';
import tommySgabelloL2x from './assets/prodotti/tommy-sgabello-l-2x.png';

import tommySgabelloM from './assets/prodotti/tommy-sgabello-m.png';
import tommySgabelloM2x from './assets/prodotti/tommy-sgabello-m-2x.png';

import tommyswSgabelloImbottito from './assets/prodotti/tommysw-sgabello-imbottito.png';
import tommyswSgabelloImbottito2x from './assets/prodotti/tommysw-sgabello-imbottito-2x.png';

import tommyswSgabello from './assets/prodotti/tommysw-sgabello.png';
import tommyswSgabello2x from './assets/prodotti/tommysw-sgabello-2x.png';

import treeTavolo60x60H from './assets/prodotti/tree-tavolo-60x60-h.png';
import treeTavolo60x60H2x from './assets/prodotti/tree-tavolo-60x60-h-2x.png';

import treeTavolo60x60L from './assets/prodotti/tree-tavolo-60x60-l.png';
import treeTavolo60x60L2x from './assets/prodotti/tree-tavolo-60x60-l-2x.png';

import treeTavolo70x70M from './assets/prodotti/tree-tavolo-70x70-m.png';
import treeTavolo70x70M2x from './assets/prodotti/tree-tavolo-70x70-m-2x.png';

import treeTavoloD60H from './assets/prodotti/tree-tavolo-d60-h.png';
import treeTavoloD60H2x from './assets/prodotti/tree-tavolo-d60-h-2x.png';

import treeTavoloD60L from './assets/prodotti/tree-tavolo-d60-l.png';
import treeTavoloD60L2x from './assets/prodotti/tree-tavolo-d60-l-2x.png';

import treeTavoloD70M from './assets/prodotti/tree-tavolo-d70-m.png';
import treeTavoloD70M2x from './assets/prodotti/tree-tavolo-d70-m-2x.png';

import viennaSediaImbottita from './assets/prodotti/vienna-sedia-imbottita.png';
import viennaSediaImbottita2x from './assets/prodotti/vienna-sedia-imbottita-2x.png';

import viennaSedia from './assets/prodotti/vienna-sedia.png';
import viennaSedia2x from './assets/prodotti/vienna-sedia-2x.png';

import viennaSgabelloImbottito from './assets/prodotti/vienna-sgabello-imbottito.png';
import viennaSgabelloImbottito2x from './assets/prodotti/vienna-sgabello-imbottito-2x.png';

import viennaSgabello from './assets/prodotti/vienna-sgabello.png';
import viennaSgabello2x from './assets/prodotti/vienna-sgabello-2x.png';


const maki = ['frassino nero', 'frassino tinta rovere naturale']
const monaco_vienna = ['frassino naturale', 'frassino giallo', 'frassino arancio', 'frassino rosa', 'frassino bordeaux', 'frassino blu', 'frassino verde', 'frassino rosso', 'frassino bianco', 'frassino greige', 'frassino nero', 'frassino smoke', 'frassino tinta noce wd59', 'abete naturale', 'frassino tinta rovere naturale', 'rovere naturale']
const vienna_sg = ['frassino naturale', 'frassino giallo', 'frassino arancio', 'frassino rosa', 'frassino bordeaux', 'frassino blu', 'frassino verde', 'frassino rosso', 'frassino bianco', 'frassino greige', 'frassino nero', 'frassino smoke', 'frassino tinta noce wd59', 'frassino tinta rovere naturale']
const frassino = ['frassino naturale', 'frassino giallo', 'frassino arancio', 'frassino rosa', 'frassino bordeaux', 'frassino blu', 'frassino verde', 'frassino rosso', 'frassino bianco', 'frassino greige', 'frassino nero', 'frassino tinta rovere naturale', 'frassino smoke', 'frassino tinta noce wd59']
const abete = ['abete naturale', 'abete giallo', 'abete arancio', 'abete rosa', 'abete bordeaux', 'abete blu', 'abete verde', 'abete rosso', 'abete bianco', 'abete greige', 'abete nero', 'abete tinta rovere naturale', 'abete smoke', 'abete tinta noce wd59']


const products = [
  {
    famiglia: "ARCO",
    name: {
      it: "Arco sedia",
      en: "Arco chair wooden seat",
    },
    code: "NW1SIWKFCS",
    hasFabric: false,
    hasWood: true,
    customMaterial: frassino,
    hasWood: true,
    thumbnail: arcoSedia,
    thumbnail2x: arcoSedia2x,
  },
  {
    famiglia: "ARCO",
    name: {
      it: "Arco sedia imbottita",
      en: "Arco chair upholstered seat",
    },
    code: "11MIKTEE7Q",
    hasFabric: true,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: arcoImbottita,
    thumbnail2x: arcoImbottita,
  },
  {
    famiglia: "COLONIAL",
    name: {
      it: "Colonial",
      en: "Colonial chair wooden seat",
    },
    code: "8FADPEAS2V",
    hasFabric: false,
    hasWood: true,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: colonialSedia,
    thumbnail2x: colonialSedia2x,
  },
  {
    famiglia: "COLONIAL",
    name: {
      it: "Colonial sedia imbottita",
      en: "Colonial chair upholstered seat",
    },
    code: "XTU808TD7V",
    hasFabric: true,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: colonialSediaImbottita,
    thumbnail2x: colonialSediaImbottita2x,
  },
  {
    famiglia: "COSSE",
    name: {
      it: "Cosse tavolo d43 h48",
      en: "Cosse coffee table width 430",
    },
    code: "RD7OSFNUAP",
    hasFabric: false,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: cosseTavoloD43,
    thumbnail2x: cosseTavoloD432x,
  },
  {
    famiglia: "COSSE",
    name: {
      it: "Cosse tavolo d54 h41",
      en: "Cosse coffee table width 540",
    },
    code: "RZI11C8QML",
    hasFabric: false,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: cosseTavoloD54,
    thumbnail2x: cosseTavoloD542x,
  },
  {
    famiglia: "CUT",
    name: {
      it: "Cut sedia",
      en: "Cut chair wooden seat",
    },
    code: "2JBTPKR3KQ",
    hasFabric: false,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: cutSedia,
    thumbnail2x: cutSedia2x,
  },
  {
    famiglia: "CUT",
    name: {
      it: "Cut sedia imbottita",
      en: "Cut chair upholstered seat",
    },
    code: "G1IT4GYZ96",
    hasFabric: true,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: cutSediaImbottita,
    thumbnail2x: cutSediaImbottita2x,
  },
  {
    famiglia: "ISA",
    name: {
      it: "Isa sedia imbottita",
      en: "Isa chair upholstered seat",
    },
    code: "3ZUUSYQOJT",
    hasFabric: true,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: isaSediaImbottita,
    thumbnail2x: isaSediaImbottita2x,
  },
  {
    famiglia: "JOLLY",
    name: {
      it: "Jolly sgabello h45",
      en: "Jolly stool h 450 wooden seat",
    },
    code: "6ZD2B0WCQP",
    hasFabric: false,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: jollySgabelloL,
    thumbnail2x: jollySgabelloL2x,
  },
  {
    famiglia: "JOLLY",
    name: {
      it: "Jolly sgabello h61",
      en: "Jolly stool h 610 wooden seat",
    },
    code: "9GQ0HUPKJY",
    hasFabric: false,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: jollySgabelloM2x,
    thumbnail2x: jollySgabelloM2x,
  },
  {
    famiglia: "JOLLY",
    name: {
      it: "Jolly sgabello h76",
      en: "Jolly stool h 760 wooden seat",
    },
    code: "I9GY93V3QX",
    hasFabric: false,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: jollySgabelloH,
    thumbnail2x: jollySgabelloH2x,
  },
  {
    famiglia: "MAKI",
    name: {
      it: "Maki tavolo d45 h51",
      en: "Maki coffee table diameter 45",
    },
    code: "82QIUMLEED",
    hasFabric: false,
    hasWood: true,
    struttura: "maki",
    customMaterial: maki,
    thumbnail: makiTavoloD45,
    thumbnail2x: makiTavoloD452x,
  },
  {
    famiglia: "MILANO",
    name: {
      it: "Milano sedia",
      en: "Milano chair wooden seat",
    },
    code: "E0WDBT9AEC",
    hasFabric: false,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: milanoSedia,
    thumbnail2x: milanoSedia2x,
  },
  {
    famiglia: "MILANO",
    name: {
      it: "Milano sedia imbottita",
      en: "Milano chair upholstered seat",
    },
    code: "BWS26797EZ",
    hasFabric: true,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: milanoSediaImbottita,
    thumbnail2x: milanoSediaImbottita2x,
  },
  {
    famiglia: "MONACO",
    name: {
      it: "Monaco sedia",
      en: "Monaco chair wooden seat",
    },
    code: "RALAPH150H",
    hasFabric: false,
    hasWood: true,
    customMaterial: monaco_vienna,
    thumbnail: monacoSedia,
    thumbnail2x: monacoSedia2x,
  },
  {
    famiglia: "MONACO",
    name: {
      it: "Monaco sedia imbottita",
      en: "Monaco chair upholstered seat",
    },
    code: "IHLEZP3NIF",
    hasFabric: true,
    hasWood: true,
    customMaterial: monaco_vienna,
    thumbnail: monacoSediaImbottita,
    thumbnail2x: monacoSediaImbottita2x,
  },
  {
    famiglia: "MONACO",
    name: {
      it: "Monaco poltrona",
      en: "Monaco armchair wooden seat",
    },
    code: "DWOXQM7VCX",
    hasFabric: false,
    hasWood: true,
    customMaterial: abete,
    thumbnail: monacoPoltrona,
    thumbnail2x: monacoPoltrona2x,
  },
  {
    famiglia: "MONACO",
    name: {
      it: "Monaco poltrona imbottita",
      en: "Monaco armchair upholstered seat",
    },
    code: "5QDO3POOL2",
    hasFabric: true,
    hasWood: true,
    customMaterial: abete,
    thumbnail: monacoPoltronaImbottita,
    thumbnail2x: monacoPoltronaImbottita2x,
  },
  {
    famiglia: "NASTY",
    name: {
      it: "Nasty tavolo d45 h50",
      en: "Nasty coffee table diameter 45 cm",
    },
    code: "Q5CMRAFX56",
    hasFabric: false,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: nastyTavolo45,
    thumbnail2x: nastyTavolo452x,
  },
  {
    famiglia: "NASTY",
    name: {
      it: "Nasty tavolo d60 h40",
      en: "Nasty coffee table diameter 60 cm",
    },
    code: "O2I126HUEG",
    hasFabric: false,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: nastyTavoloD60,
    thumbnail2x: nastyTavoloD602x,
  },
  {
    famiglia: "ONE",
    name: {
      it: "One Wood tavolo d60 h75",
      en: "One round Wood table 60 h 75",
    },
    code: "6TCGEJ2ZUZ",
    hasFabric: false,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: onewoodTavoloD60M,
    thumbnail2x: onewoodTavoloD60M2x,
  },
  {
    famiglia: "ONE",
    name: {
      it: "One Wood tavolo 60x60 h75",
      en: "One square Wood table 60 h 75",
    },
    code: "J8CUKJ9F90",
    hasFabric: false,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: onewoodTavolo60x60M,
    thumbnail2x: onewoodTavolo60x60M2x,
  },
  {
    famiglia: "ONE",
    name: {
      it: "One Wood tavolo d70 h75",
      en: "One round Wood table 70 h 75",
    },
    code: "LWKVAQ5EDK",
    hasFabric: false,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: onewoodTavoloD70M,
    thumbnail2x: onewoodTavoloD70M2x,
  },
  {
    famiglia: "ONE",
    name: {
      it: "One Wood tavolo 70x70 h75",
      en: "One square Wood table 70 h 75",
    },
    code: "UMFX2OP7NB",
    hasFabric: false,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: onewoodTavolo70x70M,
    thumbnail2x: onewoodTavolo70x70M2x,
  },
  {
    famiglia: "ONE",
    name: {
      it: "One Wood tavolo d60 h110",
      en: "One round Wood table 60 h 110",
    },
    code: "KMHJO5ISRL",
    hasFabric: false,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: onewoodTavoloD60H,
    thumbnail2x: onewoodTavoloD60H2x,
  },
  {
    famiglia: "ONE",
    name: {
      it: "One Wood tavolo 60x60 h110",
      en: "One square Wood table 60 h 110",
    },
    code: "Y69TIFLUQO",
    hasFabric: false,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: onewoodTavolo70x70H,
    thumbnail2x: onewoodTavolo70x70H2x,
  },
  {
    famiglia: "ONE",
    name: {
      it: "One Metal tavolo 60x60 h110",
      en: "One square Metal table 60 h 110",
    },
    code: "P39OUVKY3C",
    hasFabric: false,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: onemetalTavolo60x60H,
    thumbnail2x: onemetalTavolo60x60H2x,
  },
  {
    famiglia: "ONE",
    name: {
      it: "One Metal tavolo 60x60 h75",
      en: "One square Metal table 60 h 75",
    },
    code: "SQ31BZP6YK",
    hasFabric: false,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: onemetalTavolo60x60M,
    thumbnail2x: onemetalTavolo60x60M2x,
  },
  {
    famiglia: "ONE",
    name: {
      it: "One Metal tavolo 70x70 h75",
      en: "One square Metal table 70 h 75",
    },
    code: "GCE8APNR8M",
    hasFabric: false,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: onemetalTavolo70x70M,
    thumbnail2x: onemetalTavolo70x70M2x,
  },
  {
    famiglia: "PALMA",
    name: {
      it: "Palma sedia",
      en: "Palma chair wooden seat",
    },
    code: "FKO6OE4SW4",
    hasFabric: false,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: palmaSedia,
    thumbnail2x: palmaSedia2x,
  },
  {
    famiglia: "PALMA",
    name: {
      it: "Palma sedia imbottita",
      en: "Palma chair upholstered seat",
    },
    code: "9Q3F1OGV0F",
    hasFabric: true,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: palmaSediaImbottita,
    thumbnail2x: palmaSediaImbottita2x,
  },
  {
    famiglia: "PALMA SGABELLO",
    name: {
      it: "Palma sgabello h61",
      en: "Palma stool h61",
    },
    code: "LEM1SL8Q77",
    hasFabric: false,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: palmaSgabelloH61,
    thumbnail2x: palmaSgabelloH612x
  },
  {
    famiglia: "PALMA SGABELLO",
    name: {
      it: "Palma sgabello con seduta imbottita h61",
      en: "Palma stool with upholstered seat h61",
    },
    code: "6IZIXMCG9Y",
    hasFabric: true,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: palmaSgabelloH61Imbottito,
    thumbnail2x: palmaSgabelloH61Imbottito2x
  },
  {
    famiglia: "PALMA SGABELLO",
    name: {
      it: "Palma sgabello schienale h95",
      en: "Palma stool with backrest h95",
    },
    code: "W0M2Y0LX6N",
    hasFabric: false,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: palmaSgabelloSchienaleH95,
    thumbnail2x: palmaSgabelloSchienaleH952x
  },
  {
    famiglia: "PALMA SGABELLO",
    name: {
      it: "Palma sgabello con schienale e seduta imbottita h95",
      en: "Palma stool with backrest and upholstered seat h95",
    },
    code: "Y6DZH14IUI",
    hasFabric: true,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: palmaSgabelloSchienaleH95Imbottito,
    thumbnail2x: palmaSgabelloSchienaleH95Imbottito2x
  },
  {
    famiglia: "PALMA SGABELLO",
    name: {
      it: "Palma sgabello schienale h109",
      en: "Palma stool with backrest h109",
    },
    code: "DJQQRZZ72W",
    hasFabric: false,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: palmaSgabelloSchienaleH109,
    thumbnail2x: palmaSgabelloSchienaleH1092x
  },
  {
    famiglia: "PALMA SGABELLO",
    name: {
      it: "Palma sgabello con schienale e seduta imbottita h109",
      en: "Palma stool with backrest and upholstered seat h109",
    },
    code: "M0HSG9CD2X",
    hasFabric: true,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: palmaSgabelloSchienaleH109Imbottito,
    thumbnail2x: palmaSgabelloSchienaleH109Imbottito2x
  },
  {
    famiglia: "PALMA SGABELLO",
    name: {
      it: "Palma sgabello h75",
      en: "Palma stool h75",
    },
    code: "1VE59ETA0E",
    hasFabric: false,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: palmaSgabelloH75,
    thumbnail2x: palmaSgabelloH752x
  },
  {
    famiglia: "PALMA SGABELLO",
    name: {
      it: "Palma sgabello con seduta imbottita h75",
      en: "Palma stool with upholstered seat h75",
    },
    code: "RISSWJVFNS",
    hasFabric: true,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: palmaSgabelloH75Imbottito,
    thumbnail2x: palmaSgabelloH75Imbottito2x
  },
  {
    famiglia: "POP SEDIA",
    name: {
      it: "Pop sedia",
      en: "Pop chair wooden seat",
    },
    code: "UP341DQ8ZJ",
    hasFabric: false,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: popSedia,
    thumbnail2x: popSedia2x,
  },
  {
    famiglia: "POP SEDIA",
    name: {
      it: "Pop sedia imbottita",
      en: "Pop chair upholstered seat",
    },
    code: "RM7B7H2U4A",
    hasFabric: true,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: popSediaImbottita,
    thumbnail2x: popSediaImbottita2x,
  },
  {
    famiglia: "POP SGABELLO",
    name: {
      it: "Pop sgabello h76",
      en: "Pop stool wooden seat",
    },
    code: "V7Y2K7RQQ3",
    hasFabric: false,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: popSgabello,
    thumbnail2x: popSgabello2x,
  },
  {
    famiglia: "POP SGABELLO",
    name: {
      it: "Pop sgabello imbottito h76",
      en: "Pop stool upholstered seat",
    },
    code: "MM9BSSZHZZ",
    hasFabric: true,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: popSgabelloImbottito,
    thumbnail2x: popSgabelloImbottito2x,
  },
  {
    famiglia: "T-COFFEE",
    name: {
      it: "T-Coffee sedia",
      en: "T Coffee chair wooden seat",
    },
    code: "YDLOYJLFNR",
    hasFabric: false,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: tcoffeeSedia,
    thumbnail2x: tcoffeeSedia2x,
  },
  {
    famiglia: "T-COFFEE",
    name: {
      it: "T-Coffee sedia imbottita",
      en: "T Coffee chair upholstered seat",
    },
    code: "7H1H8VABM1",
    hasFabric: true,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: tcoffeeSediaImbottita,
    thumbnail2x: tcoffeeSediaImbottita2x,
  },
  {
    famiglia: "TABLET",
    name: {
      it: "Tablet sedia",
      en: "Tablet chair wooden seat",
    },
    code: "BVVMB0XYC6",
    hasFabric: false,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: tabletSedia,
    thumbnail2x: tabletSedia2x,
  },
  {
    famiglia: "TABLET",
    name: {
      it: "Tablet sedia imbottita",
      en: "Tablet chair upholstered seat",
    },
    code: "0M5LMKH848",
    hasFabric: true,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: tabletSediaImbottita,
    thumbnail2x: tabletSediaImbottita2x,
  },
  {
    famiglia: "TABLET",
    name: {
      it: "Tablet poltrona",
      en: "Tablet armchair wooden seat",
    },
    code: "AP4SSH6N6C",
    hasFabric: false,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: tabletPoltrona,
    thumbnail2x: tabletPoltrona2x,
  },
  {
    famiglia: "TABLET",
    name: {
      it: "Tablet poltrona imbottita",
      en: "Tablet armchair upholstered seat",
    },
    code: "V2ECAL2OFN",
    hasFabric: true,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: tabletPoltronaImbottita,
    thumbnail2x: tabletPoltronaImbottita2x,
  },
  {
    famiglia: "TABLET SGABELLO",
    name: {
      it: "Tablet sgabello h61",
      en: "Tablet stool h 610 wooden seat",
    },
    code: "4OKCRZFRE5",
    hasFabric: false,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: tabletSgabelloM,
    thumbnail2x: tabletSgabelloM2x,
  },
  {
    famiglia: "TABLET SGABELLO",
    name: {
      it: "Tablet sgabello imbottito h61",
      en: "Tablet stool h 610 upholstered seat",
    },
    code: "GXZD9C8M6O",
    hasFabric: true,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: tabletSgabelloImbottitoM,
    thumbnail2x: tabletSgabelloImbottitoM2x,
  },
  {
    famiglia: "TABLET SGABELLO",
    name: {
      it: "Tablet sgabello h76",
      en: "Tablet stool h 760 wooden seat",
    },
    code: "Q6EAC3J7TU",
    hasFabric: false,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: tabletSgabelloH,
    thumbnail2x: tabletSgabelloH2x,
  },
  {
    famiglia: "TABLET SGABELLO",
    name: {
      it: "Tablet sgabello imbottito h76",
      en: "Tablet stool h 760 upholstered seat",
    },
    code: "U72DBJGK6U",
    hasFabric: true,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: tabletSgabelloImbottitoH,
    thumbnail2x: tabletSgabelloImbottitoH2x,
  },
  {
    famiglia: "TOMMY",
    name: {
      it: "Tommy sgabello h45",
      en: "Tommy stool h 450 wooden seat",
    },
    code: "I7L3VN1SM6",
    hasFabric: false,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: tommySgabelloL,
    thumbnail2x: tommySgabelloL2x,
  },
  {
    famiglia: "TOMMY",
    name: {
      it: "Tommy sgabello imbottito h45",
      en: "Tommy stool h 450 upholstered seat",
    },
    code: "15E2603GV1",
    hasFabric: true,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: tommySgabelloImbottitoL,
    thumbnail2x: tommySgabelloImbottitoL2x,
  },
  {
    famiglia: "TOMMY",
    name: {
      it: "Tommy sgabello h61",
      en: "Tommy stool h 610 wooden seat",
    },
    code: "R1PL3M3P57",
    hasFabric: false,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: tommySgabelloM,
    thumbnail2x: tommySgabelloM2x,
  },
  {
    famiglia: "TOMMY",
    name: {
      it: "Tommy sgabello imbottito h61",
      en: "Tommy stool h 610 upholstered seat",
    },
    code: "15G2UQZJ3W",
    hasFabric: true,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: tommySgabelloImbottitoM,
    thumbnail2x: tommySgabelloImbottitoM2x,
  },
  {
    famiglia: "TOMMY",
    name: {
      it: "Tommy sgabello sw",
      en: "Tommy stool h 610 adjustable wooden seat",
    },
    code: "YSE2256P3R",
    hasFabric: false,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: tommyswSgabello,
    thumbnail2x: tommyswSgabello2x,
  },
  {
    famiglia: "TOMMY",
    name: {
      it: "Tommy sgabello sw imbottito",
      en: "Tommy stool h 610 adjustable upholstered seat",
    },
    code: "V5JOCNDVA8",
    hasFabric: true,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: tommyswSgabelloImbottito,
    thumbnail2x: tommyswSgabelloImbottito2x,
  },
  {
    famiglia: "TOMMY",
    name: {
      it: "Tommy sgabello h76",
      en: "Tommy stool h 760 wooden seat",
    },
    code: "4S5XFKI4BV",
    hasFabric: false,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: tommySgabelloH,
    thumbnail2x: tommySgabelloH2x,
  },
  {
    famiglia: "TOMMY",
    name: {
      it: "Tommy sgabello imbottito h76",
      en: "Tommy stool h 760 upholstered seat",
    },
    code: "BV0QAAHQ9R",
    hasFabric: true,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: tommySgabelloImbottitoH,
    thumbnail2x: tommySgabelloImbottitoH2x,
  },
  {
    famiglia: "TREE",
    name: {
      it: "Tree tavolo cm60x60 quadrato",
      en: "Tree square table 60 cm",
    },
    code: "FDL5T031N3",
    hasFabric: false,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: treeTavolo60x60L,
    thumbnail2x: treeTavolo60x60L2x,
  },
  {
    famiglia: "TREE",
    name: {
      it: "Tree tavolo d60 rotondo",
      en: "Tree round table 60 cm",
    },
    code: "U1PF8CP68T",
    hasFabric: false,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: treeTavoloD60L,
    thumbnail2x: treeTavoloD60L2x,
  },
  {
    famiglia: "TREE",
    name: {
      it: "Tree tavolo cm70x70 quadrato",
      en: "Tree square table 70 cm",
    },
    code: "X3IJA5KILU",
    hasFabric: false,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: treeTavolo70x70M,
    thumbnail2x: treeTavolo70x70M2x,
  },
  {
    famiglia: "TREE",
    name: {
      it: "Tree tavolo d70 rotondo",
      en: "Tree round table 70 cm",
    },
    code: "XM733X3KRC",
    hasFabric: false,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: treeTavoloD70M,
    thumbnail2x: treeTavoloD70M2x,
  },
  {
    famiglia: "TREE",
    name: {
      it: "Tree tavolo cm60x60 quadrato h110",
      en: "Tree square table 60 cm h 110",
    },
    code: "CV1BAFPBVG",
    hasFabric: false,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: treeTavolo60x60H,
    thumbnail2x: treeTavolo60x60H2x,
  },
  {
    famiglia: "TREE",
    name: {
      it: "Tree tavolo d60 rotondo h110",
      en: "Tree round table 60 cm h 110",
    },
    code: "H16FJ0XA25",
    hasFabric: false,
    hasWood: true,
    customMaterial: frassino,
    thumbnail: treeTavoloD60H,
    thumbnail2x: treeTavoloD60H2x,
  },
  {
    famiglia: "VIENNA",
    name: {
      it: "Vienna sedia",
      en: "Vienna chair wooden seat",
    },
    code: "DBCKXYN9Z2",
    hasFabric: false,
    hasWood: true,
    hasWood: true,
    customMaterial: monaco_vienna,
    thumbnail: viennaSedia,
    thumbnail2x: viennaSedia2x,
  },
  {
    famiglia: "VIENNA",
    name: {
      it: "Vienna sedia imbottita",
      en: "Vienna chair upholstered seat",
    },
    code: "L9GOW45EAR",
    hasFabric: true,
    hasWood: true,
    customMaterial: monaco_vienna,
    thumbnail: viennaSediaImbottita,
    thumbnail2x: viennaSediaImbottita2x,
  },
  {
    famiglia: "VIENNA SGABELLO",
    name: {
      it: "Vienna sgabello ",
      en: "Vienna stool wooden seat",
    },
    code: "GXSTQU4KB5",
    hasFabric: false,
    hasWood: true,
    customMaterial: vienna_sg,
    thumbnail: viennaSgabello,
    thumbnail2x: viennaSgabello2x,
  },
  {
    famiglia: "VIENNA SGABELLO",
    name: {
      it: "Vienna sgabello imbottito",
      en: "Vienna stool upholstered seat",
    },
    code: "Q65AUR2CEV",
    hasFabric: true,
    hasWood: true,
    customMaterial: vienna_sg,
    thumbnail: viennaSgabelloImbottito,
    thumbnail2x: viennaSgabelloImbottito2x,
  }
];

export default products